import React from 'react';

export const ContactBubbles = () => {
  return (
    <div className="contact-bubbles">
      <em className="bubble bubble-1">
        <div className="contact-image">
          <img src="images/iwona.jpg" alt="Iwona Libich" />
        </div>
      </em>
      <em className="bubble bubble-2" />
      <em className="bubble bubble-3" />
      <em className="bubble bubble-4" />
      <em className="bubble bubble-5" />
    </div>
  );
};
