import React from 'react';
import classnames from 'classnames';
import Layout from '../components/layout';
import { ContactBubbles } from '../components/bubble-groups/contact-bubbles';
import styles from './contact.module.scss';

const Index = () => (
  <Layout>
    <div className={styles.contact}>
      <h2>Contact</h2>
      <div className={styles.contactDetails}>
        <ContactBubbles />
        <div className={classnames([styles.info, styles.name])}>
          Iwona Libich
        </div>
        <div className={classnames([styles.info, styles.phone])}>
          <span>phone</span>+44 7508 609 703
        </div>
        <div className={classnames([styles.info, styles.email])}>
          <span>email</span>iwona.libich@gmail.com
        </div>
      </div>
    </div>
  </Layout>
);

export default Index;
